<!--
 * @version: 1.0
 * @Date: 2023-05-04 18:48:33
 * @LastEditors: 杨慢慢
-->
<template>
  <div :class="{'date-time-wrapper': true, 'design-special-style': true, 'design-form-style': showDuration}">
    <div class="">
      <div :class="{'label':true, 'label-heigth': !showDuration}">
        <span class="required-icon" v-if="required">*</span>
        <span>{{title}}</span>
      </div>
      <div class="range">
        <span class="placeholder">{{placeholder[0]}}</span>
        <span class="placeholder">→</span>
        <span class="placeholder">{{placeholder[1]}} </span>
        <ClockCircleOutlined style="color: rgba(0, 0, 0, 0.25); position: absolute; right: 10px" />
      </div>
      <div class="placeholder"></div>
    </div>
    <div>
      <div class="flex-layout" v-if="showDuration">
        <div class="label" >{{durationTitle}}（{{format==='yyyy-MM-DD'?'天':'小时'}}）</div>
        <div class="label">自动计算</div>
      </div>
      <div class="tips" v-if="tipsText">{{tipsText}}</div>
    </div>
  </div>
</template>

<script setup>
import { ClockCircleOutlined } from '@ant-design/icons-vue'
defineProps(['title','durationTitle','placeholder', 'tipsText', 'required','format', 'showDuration'])
</script>

<style lang='less' scoped>
.flex-layout {
  justify-content: space-between;
  padding: 10px 0 0px;
}
.range {
  position: relative;
  margin-top: 8px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.label-heigth {
  margin-top: 18px;
}
.required-icon{
  color:#c3161c !important;
}
</style>
